.image-section {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .full-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    box-sizing: border-box;
  }
  