.masked-face-container {
  left: 25%;
  top: 5%;
  transform: scale(0.7);
  width: 80%;
  height: 80%; 
  overflow: hidden;
}

.animated-background {
  position: absolute;
  top: 0;
  left: 30%;
  width: 85%; 
  height: 100%; 
}

.static-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 20;
}
