.text-section {
    display: flex;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color:  rgb(210, 210, 210) ;
  }
  
  .column {
    flex: 1;
    padding: 20px;
  }
  
  .column-left {
    flex-basis: 30%;
  }
  
  .column-right {
    flex-basis: 70%;
  }
  