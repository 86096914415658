
.writing-piece {
    cursor: pointer;
    padding: 20px;
    margin-bottom: 10px; /* Fixed separation between bars */
    background-color: #f8f8f8;
    transition: all 0.3s ease;
  }
  
  .writing-piece .title {
    font-weight: bold;
  }
  
  .writing-piece.open .content {
    padding-top: 20px;
  }
  