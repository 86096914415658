.project-pair {
    display: flex;
    align-items: center;
    margin-bottom: 20px; 
  }
  
  .project-media {
    flex: 1;
    cursor: pointer; 

  }
  
  .project-media img,
  .project-media video {
    width: 100%; 
    max-width: 400px; 
    height: auto;

  }
  
  .project-description {
    flex: 1;
    padding-left: 20px; 
  }
  