
.about-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .text-box {
    flex: 1;
    overflow-y: auto;
    height: 80%;
  }
  
  .overlay-container {
    position: relative;
    height: 80%;
  }

  .masked-face-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20; 
  }
  