
.tech-background-container {
  position: relative; 
  height: 100vh; 
}

.projects-container {
  position: absolute; 
  top: 20%;
  left: 50%;
  transform: translateX(-50%); 
  width: 80%; 
  max-width: 1200px; 
  z-index: 2; 
  overflow-y: auto; 
  height: 80%; 
  color: aliceblue;
}
