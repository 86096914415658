.text-box {
    position: absolute;
    top: 15%; 
    left: 0; 
    width: 50%; 
    height: 60%; 
    z-index: 20; 
    color: aliceblue;
    overflow-y: auto; 
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

.text-box::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
  