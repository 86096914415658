
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .writing-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .writing-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

.writing-background {
    position: relative;
    height: 100vh;
  }
  
  .writing-container {
    position: absolute; /* Position over the image section */
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; /* Center in the available space */
    max-width: 800px;
    height: auto; /* Let it be as tall as its content */
    padding: 20px; /* Add some spacing */
    overflow-y: auto;
    background: rgba(255, 255, 255, 0.0); /* Optional: Add a translucent background */
    /* Add your scroll hiding styles here as before */
  }
  
  