.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 0.3s;
  z-index: 100;
  height: 70px;
}

.header-title-link {
  text-decoration: none; /* Removes the underline */
  color: inherit; 
}

.header-title-link:hover, .header-title-link:active {
  text-decoration: none; /* Removes the underline on hover/active */
  color: inherit; /* Prevents color change on hover/active */
}


  
  .header-logo {
    width: 50px;
    height: auto;
    margin-right: 20px;
  }
  
  .header-title {
    margin: 0 20px;
    color: rgb(210, 210, 210)  
  }
  
  .header-link {
    margin: 0 10px;
    text-decoration: none;
    color: rgb(210, 210, 210) 
  }

  